<template>
    <div :class="`flex flex-row items-center gap-4 bg-white rounded-lg p-4 col-span-2 ${isRanked && rank === 1 ? 'col-start-1 lg:col-start-2' : ''}`">
        <div class="rounded-full w-24 h-24 flex items-center justify-center" :style="`background-image: url(${thumbnail}); background-size: cover`">
            <div v-if="isRanked" class="flex flex-row items-center gap-2">
                <feather-icon v-if="rank <= 3" icon="AwardIcon" svgClasses="align-middle w-8 h-8" :class="`align-middle ranking-badge ranking-badge-${rank}`"/>
                <h1 class="mt-2 text-white ranking-position">{{ rank }}</h1>
            </div>

        </div>
        <div class="flex flex-column gap-2">
            <h5 class="mb-0">{{ username }}</h5>
            <p class="mb-0">{{ value }}</p>
            <p class="text-sm mb-0">{{ value2 }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name:'challenge-standing',
    props: {
        username: {type: String, required: true},
        thumbnail: {type: String, required: true},
        value: {type: String, required: true},
        value2: {type: String, required: false},
        isRanked: {type: Boolean, required: true},
        rank: {type: Number, required: false},
    },
}
</script>

<style scoped>
    .ranking-position {
        text-shadow: black 1px 0 5px;
    }

    .ranking-badge {
        filter: drop-shadow(0px 1px 3px black);
    }

    .ranking-badge-1 {
        color: gold;
    }

    .ranking-badge-2 {
        color: silver;
    }

    .ranking-badge-3 {
        color: peru;
    }
</style>
